<template>
	<div id="navigation">
		<div class="navTop">
			<div class="item" 
				v-bind:class="{ active: navId == 1}"
				v-on:click="setTopNav(1)"
			>
				NR 2023
			</div>
			<div class="item" 
				v-bind:class="{ active: navId == 2 }"
				v-on:click="setTopNav(2)"
			>
				BGR - Wahl 2023
			</div>
			<div class="item" 
				v-bind:class="{ active: navId == 3}"
				v-on:click="setTopNav(3)"
			>
				GR 2020
			</div>
		</div>

		<div v-if="navId == 2" class="navDetail navBGR">
			<div class="item" 
				v-bind:class="{ active: routeAcive == 'bgr-graph' }"
				v-on:click="setRoute('bgr-graph');">
				Panaschier - Grafik
			</div>
			<div class="item" 
				v-on:click="setRoute('bgr-table');"
				v-bind:class="{ active: routeAcive == 'bgr-table' }"
			>
				Kandidierende
			</div>
		</div>

		<div v-if="navId == 3" class="navDetail navGRS">
			<div class="item" 
				v-bind:class="{ active: routeAcive == 'gr-graph' }"
				v-on:click="setRoute('gr-graph');"
			>
				Panaschier - Grafik
			</div>
			<div class="item" 
				v-bind:class="{ active: routeAcive == 'gr-table' }"
				v-on:click="setRoute('gr-table');">
				Kandidierende
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Navigation",
		methods: {
			setRoute(route) {
				this.$router.push(route);
				this.routeAcive = route;
			},
			setTopNav(navId) {
				this.navId = navId;
				switch (navId) {
					case 1: 
						this.$router.push('/');
						this.routeAcive = '/';
						break;
					case 2: 
						this.$router.push('bgr-graph');
						this.routeAcive = 'bgr-graph';
						break;
					case 3: 
						this.$router.push('gr-graph');
						this.routeAcive = 'gr-graph';
						break;
				} 

			}
		},
		mounted() {
			if (window.location.href.includes('bgr')) {
				this.routeAcive = 'bgr-graph';
			} else {
				this.routeAcive = '/';
			}
		},
		data() {
			return {
				navId: 1,
				routeAcive: '/'
			}
		}
	}
</script>

<style scoped>
	#navigation {
		color: white;
		width: 100%;
		height: 50px;
		position: fixed;
		border-bottom: 2px solid white;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		top: 0;
		background-color: #da3d44;
		z-index: 99;
	}

	.navTop {
		display: flex;
		flex-direction: row;
		width: 60%;
	}

	.navDetail {
		display: flex;
		flex-direction: row;
		width: 348px;
		background-color: #455754;
		align-items: flex-end;
				border-bottom: 2px solid white;
				border-left: 2px solid white;

	}

	.item {
		border-right: 2px solid white;
		font-size: 20px;
		text-align: center;
		padding: 11px;
	}

	.item:hover {
		background-color: black;
		color: white;
		cursor: pointer;
	}

	.active {
		background-color: black;
	}
</style>