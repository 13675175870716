import Vue from 'vue'
import App from './App.vue'
import router from './router'

import '../node_modules/@ag-grid-community/core/dist/styles/ag-grid.css';
import '../node_modules/@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import '../node_modules/bootstrap/dist/css/bootstrap.css';

import '@ag-grid-community/client-side-row-model';
import '@ag-grid-community/infinite-row-model';
import '@ag-grid-community/csv-export';

Vue.config.productionTip = false;

new Vue({
	router,
	render: h => h(App),
}).$mount('#app')
